
@import "~assets/styles/common";

.titleLine {
  display: flex;
  color: $color-white;
  border-left: 5px solid #e63f00;
  font-size: 30px;
  font-weight: bold;

  @include media-breakpoint-down(xs) {
    font-size: 24px;
  }

  @media (max-width: 329.98px) {
    font-size: 22px;
  }
}

.title {
  text-decoration: none;
  color: $color-white;

  &:hover {
    color: $color-white;
  }
}
