
@import "~assets/styles/common";

.preview {
  border-top: 1px solid #333;
  cursor: pointer;
  color: #ccc;
  font-size: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }

  @media (max-width: 329.98px) {
    font-size: 15px;
  }

  &:hover {
    background-color: #ccc;
    color: $color-dark;
  }
}
