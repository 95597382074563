
@import "~assets/styles/common";

.header {
  display: flex;
  align-items: center;
  color: $color-white;
  font-size: 28px;
  font-weight: bolder;
}

.btnCircle {
  width: 40px;
  height: 40px;
  padding: 5px 16px !important;
  font-size: 18px !important;
  border-radius: 20px !important;
  color: $color-white !important;

  &:hover {
    color: $color-white !important;
    background-color: #333 !important;
  }
}

.info {
  color: grey;
  font-size: 18px;
}

.menu {
  width: 120px;
  z-index: 900 !important;
}

.content {
  color: $color-white;
  font-size: 20px;
  line-height: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: Inconsolata, 細明體, sans-serif, monospace;

  a {
    color: #888;
    text-decoration: underline;
    word-wrap: break-word;

    &:hover {
      color: #aaa;
    }
  }

  img {
    max-width: 100%;
    max-height: 800px;
  }
}

@include media-breakpoint-down(xs) {
  .header { font-size: 24px; }
  .info { font-size: 16px; }
  .content { font-size: 16px; }
}

@media (max-width: 329.98px) {
  .header { font-size: 22px; }
}


._1539kb4KMXLFwHawrQ1CkY {
    margin-top: 40px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

._3ac81VC3FuUHWAbt8oS2pD {
    display: block;
    background-color: 
    #b34126;
    width: 3px;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    content: '';
    margin-right: 10px;
}

h2._2EXjEs9OBwJ0QSV2zQL5ce {
    font-size: 1.5rem;
    display: inline-block;
    line-height: 1;
    margin: 4px 0 2px;
}

h1, h2, ._2UJoI4NHpCQWMv4Hl469E7 {
    font-family: Helvetica, 'Microsoft JhengHei', Arial, sans-serif, sans-serif;
}