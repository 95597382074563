
@import '~assets/styles/common';

.logo {
  color: $color-white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 0 35px;

  @media (max-width: 329.98px) {
    font-size: 24px;
  }
}

.homePage {
  font-size: 28px;
  padding: 15px 0;

  @include media-breakpoint-up(lg) {
    margin: 0 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0;
    margin: 0 20px;
  }
}

.navbarLogo img{
  max-height: 48px;
  width: auto;
}