
@import "~assets/styles/common";

.wrapper {
  padding-top: 37vh;
  text-align: center;

  .text1 {
    color: $color-white;
    font-size: 36px;

    @include media-breakpoint-down(sm) { font-size: 32px; }

    @include media-breakpoint-down(xs) { font-size: 28px; }
  }

  .text2 {
    color: grey;
    font-size: 22px;

    @include media-breakpoint-down(sm) { font-size: 20px; }

    @include media-breakpoint-down(xs) { font-size: 18px; }
  }
}
