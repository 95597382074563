
@import "~assets/styles/common";

.inputBoard {
  height: 44px !important;
  font-size: 18px !important;

  @include media-breakpoint-down(xs) {
    height: 36px !important;
    width: 120px !important;
    padding: 6px !important;
    &::placeholder { font-size: 14px !important; }
  }
}

.inputLong {
  width: 240px !important;

  @include media-breakpoint-down(xs) {
    width: 200px !important;
    &::placeholder { font-size: 14px !important; }
  }

  @media (max-width: 329.98px) {
    width: 180px !important;
  }
}

.inputHome {
  width: 100%;
  height: 56px;
  border-radius: 30px;

  &::placeholder {
    font-size: 20px;
    color: grey;
  }

  &:focus {
    outline-width: 0;

    &::placeholder {
      color: transparent;
    }
  }

  @include media-breakpoint-down(xs) {
    height: 42px;
    &::placeholder { font-size: 18px; }
  }
}

.btnWrapper {
  position: absolute;
  right: 35px;
}

.btn {
  border-radius: 30px !important;
  background-color: $color-black !important;
  color: $color-white !important;
}
