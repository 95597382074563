
.spinnerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 70px;
  margin-top: -35px;
  margin-left: -35px;
  color: grey;
}

.spinner {
  display: flex;
  justify-content: center;
  font-size: 50px;
  margin-top: -20px;
  color: grey;
}
