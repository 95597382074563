
@import "bootstrap-4.3.1-scss/bootstrap";
@import 'common';
$fa-font-path:"/webfonts";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

html {
  position: relative;
}

body {
  margin: 0;
  min-width: 330px;
  position: relative;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  background-color: $color-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clickable { cursor: pointer; }

.bg-white { background-color: $color-white !important; }
.bg-black { background-color: $color-black !important; }
.bg-dark { background-color: $color-dark !important; }
.bg-grey { background-color: $color-grey !important; }

.text-white { color: $color-white !important; }
.text-black { color: $color-black !important; }
.text-dark { color: $color-dark !important; }
.text-grey { color: $color-grey !important; }
.text-light-grey { color: lightgrey !important; }

// below are ptt original styles
.b0 { background-color: #000; }
.b1 { background-color: #b00; }
.b2 { background-color: #0b0; }
.b3 { background-color: #bb0; }
.b4 { background-color: #00b; }
.b5 { background-color: #b0b; }
.b6 { background-color: #0bb; }
.b7 { background-color: #bbb; }

.f0 { color: #000; }
.f1 { color: #900; }
.f2 { color: #090; }
.f3 { color: #990; }
.f4 { color: #009; }
.f5 { color: #909; }
.f6 { color: #099; }
.f7 { color: #999; }

.hl { color: #fff; }
.hl.f0 { color: #666; }
.hl.f1 { color: #f66; }
.hl.f2 { color: #6f6; }
.hl.f3 { color: #ff6; }
.hl.f4 { color: #66f; }
.hl.f5 { color: #f6f; }
.hl.f6 { color: #6ff; }
.hl.f7 { color: #fff; }

.richcontent {
  position: relative;
  text-align: center;
  width: 100%;
  height: auto;
  margin: 0.5em auto;
  max-width: 800px;
  display: flex; // important, or resize-container will not align to top of this
  justify-content: center;
}

.resize-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.resize-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex; // important, or youtube-player will not align to top of this
}