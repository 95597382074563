
@import "~assets/styles/common";

$tab-width: 29px;
$tab-width-xs: 23px;

.statistic {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  color: #888;
  background-color: $color-grey;
  border: 1px solid #444;
  border-radius: 6px;

  @include media-breakpoint-down(xs) { font-size: 18px; }
}

.push { color: green; }
.boo { color: red; }
.arrow { color: $color-white; }

.commentWrapper {
  display: flex;
  font-size: 20px;
  line-height: 130%;

  @include media-breakpoint-down(md) { font-size: 18px; }

  @include media-breakpoint-down(xs) { font-size: 16px; }

  .icon {
    width: $tab-width;
    min-width: $tab-width;

    @include media-breakpoint-down(xs) {
      width: $tab-width-xs;
      min-width: $tab-width-xs;
    }
  }

  .comment {
    display: flex;
    flex-direction: column;
    flex: 1;

    .author { color: #ccc; }
    .rank { color: #ffc733; }

    .text {
      color: #ffc733;
      display: flex;
    }

    .floor {
      color: #ffc733;
      flex: 1;
    }

    .ipDateTime {
      color: #aaa;
    }
  }
}

.authorResponse {
  line-height: 130%;
  margin-left: $tab-width;

  @include media-breakpoint-down(xs) {
    margin-left: $tab-width-xs;
  }
}
