
@import "~assets/styles/common";

.preview {
  border-top: 1px solid #333;
  cursor: pointer;
}

.badge {
  display: inline-block;
  color: #666;
  border: solid 1px #666;
  border-radius: 3px;
  padding: 0 2px;
  font-size: 14px;
}

.title {
  color: $color-white;
  font-size: 22px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  color: #888;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pin {
  display: inline;
  color: #666;
  font-size: 14px;
  border: 1px solid #666;
  border-radius: 5px;
  padding: 0 2px;
}

.top {
  display: flex;
  align-items: center;
  color: #666;

  .push.grey { color: #666 !important; }
  .push.white { color: $color-white !important; }
  .push.green { color: #090 !important; }
  .push.yellow { color: #f6db55 !important; }
  .push.red { color: crimson !important; }
}

.bottom {
  display: flex;
  align-items: center;
  color: #666;

  .push {
    flex: 1;
  }

  .push.grey { color: #666 !important; }
  .push.white { color: $color-white !important; }
  .push.green { color: #090 !important; }
  .push.yellow { color: #f6db55 !important; }
  .push.red { color: crimson !important; }
}

.image {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  margin-left: 15px;
}

@include media-breakpoint-down(xs) {
  .title { font-size: 18px; }
  .content { font-size: 16px; }
  .top { font-size: 14px; }
  .bottom { font-size: 14px; }

  .image {
    width: 100px;
    height: 100px;
  }
}
