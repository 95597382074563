
@import "~assets/styles/common";

.backgroundMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
}

.wrapper {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  background-color: $color-dark;
  overflow-y: scroll;

  @include media-breakpoint-down(md) {
    left: 10%;
    width: 80%;
  }

  @include media-breakpoint-down(xs) {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 8px;

    @include media-breakpoint-down(xs) {
      width: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(160, 160, 160, 0.7);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
}

.header {
  display: flex;
  align-items: center;
  color: $color-white;
  font-size: 24px;
  text-align: center;
}

.recordItem {
  color: $color-white;
  font-size: 20px;
  border-bottom: 1px solid #555;

  .headerLine {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .dateTime {
    flex-grow: 1;
    color: #aaa;
    font-weight: lighter;
  }

  .content {
    color: $color-white;
    font-size: 20px;
    line-height: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    font-family: Inconsolata, 細明體, sans-serif, monospace;

    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }

    a {
      color: #888;
      text-decoration: underline;
      word-wrap: break-word;

      &:hover {
        color: #aaa;
      }
    }

    img {
      max-width: 100%;
      max-height: 800px;
    }
  }
}
