
@import "~assets/styles/common";

.navbar {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbar-height;
  min-width: 330px;
  z-index: 1000;
}

.navList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $color-white;
}

.leftList {
  position: absolute;
  left: 40px;
  height: 75%;
  display: flex;
  align-items: center;

  .back {
    color: $color-white;
    margin-right: 30px;
  }
}

.rightList {
  position: absolute;
  right: 40px;
  height: 75%;
  display: flex;
  align-items: center;
}

@include media-breakpoint-down(xs) {
  .leftList {
    left: 5%;

    .back {
      margin-right: 20px;
    }
  }

  .rightList {
    right: 5%;
  }
}

.sidebarMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: 0.3s ease-in-out;
  z-index: -1;
}

.sidebarMask.show {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
}

.menuList {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: absolute;
  left: -100vw;
  width: 10%;
  height: 100%;
  min-width: 180px;
  background-color: $color-grey;
  color: $color-white;
  transition: 0.3s ease-in-out;

  .menuItem {
    display: flex;
    font-size: 18px;
    text-decoration: none;
    color: $color-white;
    border-radius: 5px;

    &:hover {
      background-color: grey;
    }
  }

  .active {
    background-color: grey;
  }

  &::-webkit-scrollbar {
    width: 8px;

    @include media-breakpoint-down(xs) {
      width: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(160, 160, 160, 0.7);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
}

.menuList.show {
  left: 0;
  z-index: 1200;
}
